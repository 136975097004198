import React, { useEffect } from "react"
import { navigate } from "gatsby"
import useFetch from "react-fetch-hook"
import { useSpring, config, animated } from "react-spring"
import SEO from "../components/seo"
import HeroContainer, { HeroContent } from "../components/containers/hero"
import { Button } from "../components/button"
import { Rate } from "../utils/api"
import siteConfig from "../utils/config"
import { formatRate, formatDate, formatCurrency } from "../utils/format"

import classes from "../styles/error.module.css"

const NotFoundPage = () => {
  const { data: rate, isLoading } = useFetch<Rate>(
    `${siteConfig.apiUrl}/loan-finance/rate`
  )

  const [ratePercent, setRatePercent] = useSpring(() => ({
    value: 0.05,
    from: { value: 0.05 },
  }))

  const [subHeaderProps, setSubHeaderProps] = useSpring(() => ({
    opacity: 0,
  }))

  useEffect(() => {
    if (!rate) {
      return
    }

    setRatePercent({
      value: rate.rate,
      delay: 500,
      config: {
        ...config.gentle,
        precision: 0.0001,
      },
    })
    setSubHeaderProps({
      opacity: 1,
    })
  }, [rate])

  return (
    <>
      <HeroContainer variant="light" className={classes.heroContainer}>
        <HeroContent>
          <SEO title="404: Not found" />
          <h2>Oops!</h2>
          <p>
            We were going to show you a 404 error. But instead here’s today’s
            lowest refinance rate:
          </p>
          <animated.h1>
            {ratePercent.value.to(value => formatRate(value))}
          </animated.h1>
          <animated.h5 style={subHeaderProps}>
            {formatRate(rate ? rate.apr : 0)} APR •{" "}
            {rate ? formatDate(rate.lastUpdatedTime) : ""} •{" "}
            {rate ? rate.loanTerm : ""} {rate ? rate.name : ""} •{" "}
            {formatCurrency(rate ? rate.options.loanAmount : 0)} Loan Amount
          </animated.h5>
          <Button isHero onClick={() => navigate("/")}>
            Back to Homepage
          </Button>
        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default NotFoundPage
